/**
 * Gallery Carousel
 * @description :
 */

(function () {

    document.querySelectorAll('.js-image-popout-src').forEach(function(item) {
        item.addEventListener('click', function(e) {
            e.preventDefault();
            window.galleryModal.classList.add('c-image-modal--open');
            window.gallerySwiper.slideTo(item.dataset.swiperIndex);
        })
    });

    document.querySelectorAll('.js-image-modal').forEach(item => {
        window.galleryModal = item;
        item.querySelector('.js-image-modal-close').addEventListener('click', function(e) {
            item.classList.remove('c-image-modal--open');
        })
    })

    document.querySelectorAll('.js-gallery-carousel').forEach(rootEl => {
        const swiperEl = rootEl;
        const slideEls = rootEl.querySelectorAll('.js-gallery-carousel-slide');

        const prevEl = document.querySelector('.js-gallery-carousel-prev');
        const nextEl = document.querySelector('.js-gallery-carousel-next');

        if (slideEls.length < 2) {
            // We only have one slide, so don't create a Swiper instance.
            return;
        }

        window.gallerySwiper = new Swiper(swiperEl, {
            loop: true,
            slidesPerView: 1,
            navigation: {
                prevEl,
                nextEl
            },
            pagination: {
                clickable: true
            },
            speed: 800
        });
    })

    if (window.innerWidth > 768) {
        window.addEventListener('scroll', e => {
            document.querySelectorAll('.js-movement-track').forEach(item => {
                const prevSibling = item.previousElementSibling;
                item.style.transform = `translateY(${prevSibling.getBoundingClientRect().top/5}px)`;
            })
        })
        window.scroll();
    }

})();
